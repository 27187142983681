.fl {
    float: left;
}

.fr {
    float: right;
}

a, a:hover {
    text-decoration: none;
}

.border-top {
    border-top: 1px solid #eee;
}

.of-hidden {
    overflow: hidden;
}

.of-x-hidden {
    overflow-x: hidden;
}

.of-y-hidden {
    overflow-y: hidden;
}

.container {

    &.full-width {
        width: 100%;
        padding: 0;
    }
}

.none {
    display: none;
}

.clear {
    clear: both;
}

.flickity {
    margin-bottom: 30px;

    &.hide-arrows {

        .previous {
            display: none;
        }

        .next {
            display: none;
        }
    }
}

.row {

    &.reverse {
        display: flex;
        flex-direction: row-reverse;
    }
}

.light-gray-bg {
    background: $light-gray-bg;
}

.gallery:after {
    content: 'flickity';
    display: none; /* hide :after */
}

.color-blue {
    color: #43a6eb;
}

.white-bg {
    background: #fff;
}

.text-center {
    text-align: center;
}

.image-icons {
    margin-top: 10px;

    li {
        line-height: 44px;
    }
}

.img-icon {
    width: 30px;
    text-align: center;
    float: left;
    margin-right: 15px;
    margin-top: -5px;
}

.image {
    position: relative;

    img {
        width: 100%;
        display: inline-block;
    }

    .title {
        position: absolute;
        top: 20px;
        right: 20px;
        background: #43a6eb;
        color: #fff;
        text-transform: uppercase;
        border-radius: 3px;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 15px;

        @include bp(sm) {
            right: -20px;
        }
    }
}

@media screen and ( min-width: 768px ) {
    /* disable Flickity for large devices */
    .gallery:after {
        content: '';
    }
}


// Dimensions for alignments
$alignment10:               10px;
$alignment20:               20px;
$alignment30:               30px;
$alignment40:               40px;
$alignment50:               50px;
$alignment60:               60px;
$alignment100:              100px;

// Margins
.margin-10 {
    margin: $alignment10 / 2;

    @include bp(sm) {
        margin: $alignment10;
    }
}

.margin-top-10 {
    margin-top: $alignment10 / 2;

    @include bp(sm) {
        margin-top: $alignment10
    }
}

.margin-right-10 {
    margin-right: $alignment10;
}

.margin-bottom-10 {
    margin-bottom: $alignment10;
}

.margin-left-10 {
    margin-left: $alignment10;
}

.margin-20 {
    margin: $alignment20;
}

.margin-top-20 {
    margin-top: $alignment20;
}

.margin-right-20 {
    margin-right: $alignment20;
}

.margin-bottom-20 {
    margin-bottom: $alignment20;
}

.margin-left-20 {
    margin-left: $alignment20;
}

.margin-30 {
    margin: $alignment30;
}

.margin-top-30 {
    margin-top: $alignment30;
}

.margin-right-30 {
    margin-right: $alignment30;
}

.margin-bottom-30 {
    margin-bottom: $alignment30;
}

.margin-left-30 {
    margin-left: $alignment30;
}

.margin-40 {
    margin: $alignment40;
}

.margin-top-40 {
    margin-top: $alignment40;
}

.margin-right-40 {
    margin-right: $alignment40;
}

.margin-bottom-40 {
    margin-bottom: $alignment40;
}

.margin-left-40 {
    margin-left: $alignment40;
}

.margin-50 {
    margin: $alignment50;
}

.margin-top-50 {
    margin-top: $alignment50;
}

.margin-right-50 {
    margin-right: $alignment50;
}

.margin-bottom-50 {
    margin-bottom: $alignment50;
}

.margin-left-50 {
    margin-left: $alignment50;
}

.margin-60 {
    margin: $alignment60;
}

.margin-top-60 {
    margin-top: $alignment60;
}

.margin-right-60 {
    margin-right: $alignment60;
}

.margin-bottom-60 {
    margin-bottom: $alignment60;
}

.margin-left-60 {
    margin-left: $alignment60;
}

.margin-100 {
    margin: $alignment100;
}

.margin-top-100 {
    margin-top: $alignment100 / 2;

    @include bp(sm) {
        margin-top: $alignment100;
    }
}

.margin-right-100 {
    margin-right: $alignment100;
}

.margin-bottom-100 {
    margin-bottom: $alignment100 / 2;

    @include bp(sm) {
        margin-bottom: $alignment100;
    }
}

.margin-left-100 {
    margin-left: $alignment100;
}

.no-margin {
    margin: 0;
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-right {
    margin-right: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-margin-left {
    margin-left: 0;
}

@include bp(xs-sm) {

    .mobile-margin-bottom {
        margin-bottom: 15px;
    }
}

// Padding
.padding-10 {
    padding: $alignment10;
}

.padding-top-10 {
    padding-top: $alignment10;
}

.padding-right-10 {
    padding-right: $alignment10;
}

.padding-bottom-10 {
    padding-bottom: $alignment10;
}

.padding-left-10 {
    padding-left: $alignment10;
}

.padding-20 {
    padding: $alignment20;
}

.padding-top-20 {
    padding-top: $alignment20;
}

.padding-right-20 {
    padding-right: $alignment20;
}

.padding-bottom-20 {
    padding-bottom: $alignment20;
}

.padding-left-20 {
    padding-left: $alignment20;
}

.padding-30 {
    padding: $alignment30;
}

.padding-top-30 {
    padding-top: $alignment30;
}

.padding-right-30 {
    padding-right: $alignment30;
}

.padding-bottom-30 {
    padding-bottom: $alignment30;
}

.padding-left-30 {
    padding-left: $alignment30;
}

.padding-40 {
    padding: $alignment40;
}

.padding-top-40 {
    padding-top: $alignment40;
}

.padding-right-40 {
    padding-right: $alignment40;
}

.padding-bottom-40 {
    padding-bottom: $alignment40;
}

.padding-left-40 {
    padding-left: $alignment40;
}

.padding-top-bottom-40 {
    padding-top: $alignment40;
    padding-bottom: $alignment40;
}

.padding-50 {
    padding: $alignment50;
}

.padding-top-50 {
    padding-top: $alignment50;
}

.padding-right-50 {
    padding-right: $alignment50;
}

.padding-bottom-50 {
    padding-bottom: $alignment50;
}

.padding-left-50 {
    padding-left: $alignment50;
}

.padding-100 {
    padding: $alignment100;
}

.padding-top-100 {
    padding-top: $alignment100;
}

.padding-right-100 {
    padding-right: $alignment100;
}

.padding-bottom-100 {
    padding-bottom: $alignment100;
}

.padding-left-100 {
    padding-left: $alignment100;
}

@include bp(xs-sm) {
    .no-xs-padding {
        padding: 0;
    }
}

@include bp(md) {
    .no-md-padding {
        padding: 0;
    }
}

.no-padding {
    padding: 0;
}

.no-padding-top {
    padding-top: 0;
}

.no-padding-right {
    padding-right: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.no-padding-left {
    padding-left: 0;
}


