
.table-side-to-side {

	> thead > tr > th:first-of-type {
		padding-left: 0px;
	}

	> tbody > tr > td:first-of-type {
		padding-left: 0px;
	}
}

.table > :not(:first-child) {
    border-top: none;
}

.table-responsive {
	position: relative;
	
	@include bp(lg) {
		overflow: hidden;
	}
}


.table-borderless {

	img {
		width: 60px;
		margin-right: 15px;
	}

	tbody tr {
		margin: 17px 0;
	}

	tr {
		display: flex;
		align-items: center;

		th {
			font-size: 14px;
			text-transform: uppercase;
			border: none !important;
		}

		td {
			border: none !important;
			font-size: 17px;
			font-family: 'Gotham-Book';
		}
	}

	.name {
		display: flex;
		align-items: center;
		width: 280px;
	}

	.region,
	.followers,
	.likes {
		width: 120px;
	}

	.comments {
		width: 160px;
	}

	.rate {
		width: 180px;
	}
}