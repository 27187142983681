@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}








@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}



@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity:1;
    -webkit-transform:translateX(0);
    transform:translateX(0)
  }
  100% {
    opacity:0;
    -webkit-transform:translateX(20px);
    transform:translateX(20px)
  }
}
@keyframes fadeOutRight {
  0% {
    opacity:1;
    -webkit-transform:translateX(0);
    -ms-transform:translateX(0);
    transform:translateX(0)
  }
  100% {
    opacity:0;
    -webkit-transform:translateX(20px);
    -ms-transform:translateX(20px);
    transform:translateX(20px)
  }
}
.fadeOutRight {
  -webkit-animation-name:fadeOutRight;
  animation-name:fadeOutRight
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity:0;
    -webkit-transform:translateX(-20px)
  }
  100% {
    opacity:1;
    -webkit-transform:translateX(0)
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity:0;
    -moz-transform:translateX(-20px)
  }
  100% {
    opacity:1;
    -moz-transform:translateX(0)
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity:0;
    -o-transform:translateX(-20px)
  }
  100% {
    opacity:1;
    -o-transform:translateX(0)
  }
}
@keyframes fadeInLeft {
  0% {
    opacity:0;
    transform:translateX(-20px)
  }
  100% {
    opacity:1;
    transform:translateX(0)
  }
}
.fadeInLeft {
  -webkit-animation-name:fadeInLeft;
  -moz-animation-name:fadeInLeft;
  -o-animation-name:fadeInLeft;
  animation-name:fadeInLeft
}