.btn {
  transition: all 0.2s ease-out;
  box-shadow: 0 1px 2px 0 rgba(16, 18, 24, 0.24);
  border-radius: 6px;
  font-family: 'Rubik', 'Sen', sans-serif;

  &.btn-icon {
    display: flex;
    align-items: center;

    i {
      margin-right: 12px;
    }
  }

  &.btn-primary {
    background: $brand-primary;
    border-color: $brand-primary;
    color: #1c1c2c;

    &:hover,
    &:active {
      background: darken($brand-primary, 13%);
      border-color: darken($brand-primary, 13%);
    }

    &:focus {
      box-shadow: none !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled):focus {
      background: darken($brand-primary, 8%) !important;
      border-color: darken($brand-primary, 8%);
      color: #1c1c2c;
    }
  }

  &.btn-secondary {
    background: $brand-secondary;
    border-color: $brand-secondary;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

    &:hover {
      background: darken($brand-secondary, 6%);
      border-color: darken($brand-secondary, 6%);
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled):focus {
      background: darken($brand-secondary, 4%) !important;
      border-color: darken($brand-secondary, 4%);
    }
  }

  &.btn-dblue {
    background: #143563;
    border-color: #143563;
    color: #fff;

    &:hover {
      background: darken(#143563, 13%);
      border-color: darken(#143563, 13%);
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  &.btn-blue {
    background:#45B2FB;
    border-color: #45B2FB;
    color: #fff;

    &:hover {
      background: darken(#45B2FB, 13%);
      border-color: darken(#45B2FB, 13%);
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  &.btn-rounded {
    border-radius: 100px;
  }

  &.scale-effect {
    &:hover {
      transform: scale(1.05);
    }
  }
}

.btn-md {
  padding: 0.425rem 0.9rem;
  font-size: 14px;

  @include bp(md) {
    padding: 0.725rem 1.5rem;
    font-size: 16px;
  }
}

a {
  transition: all 0.1s ease-out;
}


.img-rounded {
  border-radius: 100%;
}

.arrow-down {
  color: rgba(255, 255, 255, 0.3);
  font-size: 22px;
}

.solana-text {
  color: aqua;
}

.box-bordered {
  position: relative;
  width: 100%;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;

  @include bp(md) {
    padding: 40px;
  }
}

.me-icon {
  background: url('../img/logo/me.svg') top left no-repeat;
  width: 20px;
  height: 14px;
  display: inline-block;
  margin: 0 -7px -2px 15px;
  background-size: contain;
}


.badge.badge-dark {
  background: rgba(22,22,36,0.4);
  border: 1px solid rgba(108,170,255,0.4);
  border-radius: 6px;
  font-weight: normal;
  font-size: 14px;
  color: #A7C1FF;
  text-transform: uppercase;
  padding: 0.58em 0.8em;
}

.badge {

  &.bg-bronze {
    background: #cd9e6d61;
    color: #dfa974;
  }

  &.bg-silver {
    background: #ffffff61;
  }

  &.bg-solana {
    background: #7964e882;
    color: #c59dff;
  }
}