

.intro {
	background-image: linear-gradient(180deg, #E6F1FF 72%, #C8D8FF 96%);
	height: 80vh;
	display: flex;
	align-items: flex-end;
	padding: 9% 3%;
	position: relative;
	overflow: hidden;

	@include bp(md) {
    height: 100vh;
		max-height: 900px;
		min-height: 700px;
		padding: 100px 0;
	}

	.background-video {
		bottom: 0;
		height: 100%;
		min-width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		right: 0;
		top: 0;
		width: auto;
		z-index: -100;
	}


	&:after {
		background-image: linear-gradient(179deg, rgba(0,0,0,0.00) 43%, rgba(#050506, 0.65) 100%);
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		content: '';
	}

	h1 {
		color: #fff;
		max-width: 12ch;

		@include bp(md) {
			font-size: 4rem;
			line-height: 4.8rem;
		}

		@include bp(xlg) {
			font-size: 6rem;
			line-height: 6.2rem;
		}
	}

	.lead {
		color: #fff;
		font-size: 1rem;
		max-width: 36ch;

		@include bp(md) {
			font-size: 1.5rem;
			line-height: 2.1rem;
		}
	}

	p {
		max-width: 38ch;
		font-size: 22px;
		color: #fff;
		line-height: 32px;
	}
}

.block + .block {
	margin: 8% 0;
}


.box-wrapper {
	border-radius: 18px;
	position: relative;

  @include bp(xs-sm) {

    .tag {
      font-size: 14px;
    }

    .badge {
      transform: scale(0.8) translate3d(15px, -12px, 0);
    }

    h1 {
      margin-top: 20px;
    }
  }

	h1 {
		font-family: Apparat;
		color: #fff;

		@include bp(md) {
			font-size: 70px;
			letter-spacing: -1.75px;
		}
	}

	.tag {
		margin-bottom: 5%;
	}

	p {
		max-width: 38ch;
	}


}

.launchpad {

	.background-video {
		bottom: 0;
		height: 100%;
		min-width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		right: 0;
		top: 0;
		width: auto;
		z-index: -100;
	}

  .row .col {
    position: relative;
    z-index: 1;
  }
	
	h1 {

		@include bp(md) {
			max-width: 550px;
		}

		span {
      color: aqua;
		}

	}

	p {
		opacity: 0.8;
		color: #fff;
	}

	.box-wrapper {
		padding: 25px;
		background-image: linear-gradient(222deg, rgba(74,74,114,0.68) 0%, rgba(24,25,70,0.70) 100%);
		padding-bottom: 10rem;

		@include bp(md) {
      padding: 50px;
			padding-bottom: 18rem;
		}
	}

	.preview {
		position: absolute;
		bottom: 0;
		right: 0;
    z-index: 0;

		img {
			object-fit: contain;
			width: 100%;
		}
	}
}

.block.nft {

	.box-wrapper {
    padding: 40px 30px;
		overflow: hidden;
		position: relative;
		z-index: 1;

    @include bp(md) {
      padding: 50px 50px 8rem;
    }

		.background-video {
			bottom: 0;
			height: 100%;
			min-width: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
			z-index: -100;
		}

		h1 {
			@include bp(md) {
				max-width: 15ch;
				margin-top: 2rem;
			}

			span {
				color: #cfcfcf;
    			mix-blend-mode: overlay;
			}
		}

		p {
			color: #fff;
			font-weight: 500;
		}

		&:after {
			content: '';
			position: absolute;
			top: 3px;
			left: 3px;
			width: calc(100% - 6px);
			height: calc(100% - 6px);
			background: rgba(0, 0, 0, 0.2);
			backdrop-filter: saturate(80%) blur(5px);
      -webkit-backdrop-filter: saturate(80%) blur(5px);
			z-index: -1;
			border-radius: 18px;
		}
	}
}


.block.testimonials {

  @include bp(md) {
    margin-top: -30px;
  }

  .swiper-slide {
    padding: 30px 0 0;
  }

  .swiper-slide {
    opacity: 0.2;
    transition: opacity .3s ease-out;
    width: 90%;

    @include bp(md) {
      width: 50%;
    }
  }

  .swiper-slide-active {
    opacity: 1;
  }

	h1 {

		font-weight: 600;
		color: #fff;
		text-align: center;
		max-width: 26ch;
    font-size: 22px;
    line-height: 32px;
		margin: -20px auto 0;

    @include bp(sm) {
      font-size: 36px;
      line-height: 46px;
    }
	}

  .card {
    background: none;
    color: #fff;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    border: none;

    .card-body {
      padding: 0 0 0 20px;
      flex: inherit;
      text-align: left;
    }

    h5, p {
      color: #fff;
      margin-bottom: 0;
    }

    h5 {
      font-size: 16px;

      @include bp(sm) {
        font-size: 22px;
      }
    }

    p small {
      font-size: 14px;
      opacity: 0.4;

      @include bp(sm) {
        font-size: 16px;
      }
    }

    img {
      border-radius: 100%;

      @include bp(xs-sm) {
        width: 60px;
        height: 60px;
        float: right;
      }
    }
  }
}

.block.radrugs {


  .box-wrapper {
		background-image: linear-gradient(222deg, rgba(74,74,114,0.68) 0%, rgba(24,25,70,0.70) 100%);
    overflow: hidden;
	}

  h1 {

    span {
      color: #1ED0AD;
    }
  }

  .content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}



@keyframes from0to360 {
from{transform:rotate(0);}
to{transform:rotate(360deg);}
}
@keyframes from360to0 {
0% {transform:rotate(360deg);}
50% {transform:rotate(0deg) translate3d(50px, 50px, 0);}
100% {transform:rotate(360deg);}
}
@keyframes scaleUp {
0%{transform:scale(0.6);}
50%{transform:scale(1);}
0%{transform:scale(0.6);}
}
@keyframes scaleDown {
from{transform:scale(1.2);}
to{transform:scale(0.5);}
}

@keyframes from360to0scaled {
  0%{transform:rotate(360deg) scale(0.8) translate3d(0, 0, 0); }
  50%{transform:rotate(180deg) scale(1.3) translate3d(50px, 50px, 0); }
  100%{transform:rotate(0deg) scale(0.8) translate3d(0, 0, 0);}
}



.blob {
  will-change: transform;
	filter: blur(100px);
	position: absolute;
	left: 50%;
	bottom: -70%;
	z-index: -10;
  
  @include bp(xs-md) {
    transform: translateX(-50%) translateY(-50px) scale(1) rotate(220deg);
  }

  @include bp(md) {
    transform: translateX(-50%) scale(1.5) rotate(220deg);
  }

  .circle {
    background: linear-gradient(45deg, #FF5DE5, #177DFF);
    border-radius: 100%;
    position: absolute;
    top: 50px;

    &.circle1 {
      width: 150px;
      height: 145px;
      animation: from360to0scaled 25s ease-in-out infinite;
      filter: blur(4px);
    }

    &.circle2 {
      width: 150px;
      height: 180px;
      animation: from360to0 25s ease-in-out infinite;
      transform-origin: 60px 50px;
      filter: blur(30px);
    }
    &.circle3 {
      width: 150px;
      height: 180px;
      animation: from360to0 25s ease-in-out infinite alternate;
      transform-origin: 100px 20px;
      filter: blur(30px);
    }
    &.circle4 {
      width: 220px;
      height: 180px;
      animation: from360to0 25s ease-in-out infinite;
      filter: blur(30px);
    }
    &.circle5 {
      width: 400px;
      height: 400px;
      animation: from360to0scaled 25s ease-in-out infinite;
      filter: blur(30px);
    }
  }
}




.project-list {

  & + .project-list {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 5% 0;
    padding-bottom: 5%;
  }

  .featured-content-right {

    h1 {
      color: #fff;
    }
    
    p {
      margin-bottom: 15px;
    }

    a {
      color: #fff;
      transition: .3s ease-out;

      &:hover {
        opacity: .4;
      }
    }
  }

  @include bp(xs-sm) {

    .tab-pane .row > * {
      padding-right: calc(var(--bs-gutter-x) * .3);
      padding-left: calc(var(--bs-gutter-x) * .3);
    }
  }

  .badges {
    font-family: Inter;
    font-weight: bold;
    font-size: 12px;
    color: #FFF;
    letter-spacing: -0.3px;
    background: #000;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    padding: 1px 13px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 14px;
      margin-right: 5px;

      &:nth-child(2) {
        margin-left: 14px;
      }
    }
  }


  .sol-price {
    font-family: Inter;
    font-weight: bold;
    font-size: 14px;
    color: #FFF;
    letter-spacing: -0.3px;
    background: #000;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    padding: 0 10px 0 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .badge.badge-dark {
    border: 1px solid #83858F;
    border-radius: 4px;
    background: none;
    opacity: 0.6;
    font-weight: normal;
    font-size: 12px;
    color: #fff;
    margin-right: 5px;
  }
  
  .featured-item {
    background: url('../img/clients/oblivion/featured-img.png') center center no-repeat;
    background-size: cover;
    position: relative;
    padding: 6px;
    border-radius: 14px;
    overflow: hidden;

    .sol-price {
      font-size: 18px;
      top: 20px;
      right: 20px;
      padding: 1px 11px 1px 12px
    }

    .badges {
      font-size: 14px;
      top: 20px;
      left: 20px;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      backdrop-filter: saturate(100%) blur(60px);
      -webkit-backdrop-filter: saturate(100%) blur(60px);
    }
  }

  .featured-img {
    
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      position: relative;
      z-index: 1;
      height: 100%;
    }
  }

  .featured-content {
    position: relative;
    z-index: 1;
    padding: 15px 20px;

    h3 {
      font-weight: 500;
      font-size: 34px;
      color: #FFFFFF;
      margin-bottom: 2px;

      & + p {
        text-transform: uppercase;
        font-size: 14px;
        color: #fff;
        opacity: 0.6;
      }

      &.price {
        font-size: 28px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  .nav-item .h2 {
    
    opacity: 0.3;
    transition: .2s ease-out;
    margin-right: 20px;

    @include bp(md) {
      font-size: 34px;
      margin-right: 30px;
    }

    &:hover {
      opacity: 1;
      color: #fff;
    }

    &.active {
      opacity: 1;
    }
  }

  .col-12.col-md-6 {

    @include bp(xs-sm) {

      .badges {
        bottom: 0;
        top: auto;
        transform: scale(0.7);
        left: -19px;
      }

      h3 {
        font-size: 14px;
        line-height: 24px;
        margin-top: 5px;
      }

      .badge.badge-dark {
        font-size: 10px;
        padding: 3px 5px;
      }
    }
  }

  .collection-item {
    position: relative;
    margin-bottom: 30px;

    @include bp(xs-sm) {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      justify-content: center;

      h3 {
        flex: 100%;
        text-align: center;
      }
    }

    .featured-img {
      width: 100%;
      object-fit: cover;
      position: relative;
      border-radius: 8px;
      overflow: hidden;

      @include bp(md) {
        max-width: 290px;
        height: 290px;
      }
  
      @include bp(xs-sm) {
        height: 170px;
        width: 170px;
      }

      &:hover {

        .link-overlay {
          opacity: 1;
        }
      }
    }



    h3 {
      font-weight: 600;
      font-size: 22px;
      color: #fff;
      margin-bottom: 0;
      margin-top: 8px;
    }

    .link-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;
      z-index: 3;
      backdrop-filter: saturate(180%) blur(8px);
      -webkit-backdrop-filter: saturate(180%) blur(8px);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: all .3s ease-out;
      flex-wrap: wrap;
      flex-direction: column;
      .mint-button {
        background-color: #11c374;
        color: #1c1c2c;
        display: block;
        padding: 5px 27px;
        margin-top: 10px;
        border-radius: 4px;
      }
    }

  }
}


.page-launchpad {

  .launchpad {
    text-align: center;

    .box-wrapper {
      padding-bottom: 0;

      .tag {
        margin-bottom: 3%;
        opacity: 0.6;
      }

      img {
        margin-top: 50px;
      }
    }

    h1 {
      max-width: 750px;
    }
  }

  .launchpad h1 strong {
    color: #27E790;
    font-weight: normal;
  }


.preview {
  background-image: linear-gradient(204deg, #1a1a4e 0%, #131332 100%);
  padding: 20% 0 0;
  position: relative;
  overflow: hidden;
  
  @include bp(md) {
    padding: 10% 0 0;
  }

  h1 {
    color: #fff;
    max-width: 740px;
    margin: 0 auto 30px;

    span {
      //background-image: linear-gradient(to right bottom, #41f5e8, #3553ff);
      background: none;
      -webkit-background-clip: none;
      -webkit-text-fill-color: aqua;
    }
    
    @include bp(md) {
      font-size: 70px;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.6);
  }

  .video-container {
    perspective: 1500;
    margin: 0;
    -webkit-perspective: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  
    .preview-video {
      transform: rotateX(8deg);
      box-shadow: 0px 62.5px 125px -25px rgba(50, 50, 93, 0.5), 0px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
      max-width: 1000px;
      width: 100%;
      border-radius: 8px;
    }
  }
  
}


.feature-box {
  border-radius: 12px;
  padding: 28px;
  min-height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;

  @include bp(md) {
    padding: 36px;
  }

  .spacer {
    height: 70px;
  }

  h3 {
    font-family: Apparat;
    font-weight: 500;
    font-size: 30px;
    color: #FFFFFF;
    letter-spacing: -0.75px;
    text-align: center;
    line-height: 36px;

    span {
      display: block;
    }
  }
  
  &.mint-page {
    background-image: linear-gradient(222deg, rgba(44,90,130,0.68) 0%, rgba(24,25,70,0.70) 100%);

    h3 {

      span {
        color: #26E88F;
      }
    }
  }

  &.bot-feature {
    background: url('../img/site/bot-feature.png') center center no-repeat;
    background-size: cover;

    h3 {

      span {
        color: #7C9DC6;
      }
    }
  }

  &.wl-feature {
    background: url('../img/site/whitelist-feature.png') center center no-repeat;
    background-size: cover;

    h3 {
      color: #040405;

      span {
        color: #159D61;
      }
    }
  }
  &.multi-upload {
    background: url('../img/site/multi-uppl.png') center center no-repeat;
    background-size: cover;
    text-align: left;
    align-items: baseline;
    margin-top: 0;

    h3 {
      color: #27E890;
      text-align: left;
      max-width: 14ch;

      span {
        color: #fff;
      }
    }
  }

  &.airdrops {
    background: #945a9a;
    margin-top: 0;

    h4 {
      color: #df8ee7;
    }
  }
}

}



.page-nft {

  .intro {
    background: linear-gradient(53deg, #0f1014, #364256);
  
    @include bp(xs-sm) {
      padding-top: 120px;
      height: auto;
    }

    .hero-cards {
      margin-left: 80px;
    }
  }

  img.nft-pass {
    max-width: 400px;
    width: 100%;
  }

  .nft-cards {
    background: linear-gradient(220deg, #030304, #20262f);
    margin: 0;
    padding: 8% 0;

    .bordered {
      margin: 6% 0;

      h2 {
        font-size: 24px;
        margin-bottom: 30px;
      }

      .table {
        color: #fff;
        opacity: 0.5;

        td {
          border-color: rgba(255, 255, 255, 0.15);
          padding:  .75rem 0;
        }

        tr td:first-of-type {
          width: 155px;
          padding-right: 0;
          strong {
            padding-right: 0;
          }
        }
      }
    }

    .container {
      max-width: 1100px;
    }

    .headline-box {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      color: #fff;

      p {
        opacity: .5;
      }
    }
  }

  .calc {
    h4 {
      text-align: center;
      font-weight: 400;
    }
    h1 {
      text-align: center;
      font-size: 50px;
      span {
        color: #27E790;
      }  
    }
    @include bp(xs-md) {
      h4 {
        font-size: 16px;
      }
      h1 {
        font-size: 46px;
        line-height: 1.2;
      }
    }
    @include bp(sm-md) {
      h1 {
        font-size: 36px;
      }
    }
    @include bp(xs-sm) {
      h1 {
        font-size: 36px;
      }
    }
    @media (max-width: 575px) {
      h1 {
        font-size: 30px;
      }
    }
    .box-wrapper {
      padding: 25px;
      background-image: linear-gradient(222deg, rgba(74,74,114,0.68) 0%, rgba(24,25,70,0.70) 100%);
  
      @include bp(md) {
        padding: 64px 50px;
      }
    }

    &-balance {
      justify-content: space-between;
      flex-wrap: wrap;
      input {
        background: transparent;
        border: 2px solid #8888C849;
        border-radius: 8px;
        color: white;
        &:focus, &:active {
          outline: 1px solid #8888C899;
        }
      }
      @include bp(md) {
        max-width: 770px;
        margin: auto;
        display: flex;
        .calc-card {
          width: 95px;
        }
        .calc-image {
          margin-top: -75px;
        }
        input {
          position: relative;
          z-index: 99;
          width: 100%;
          height: 110px;
          text-align: center;
          font-size: 60px;
        }
        img {
          height: 95px;
          width: auto;
          transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform-origin: left bottom;
        }
      }
      @include bp(xs-md) {
        display: flex;
        justify-content: space-between;
        .calc-card {
          display: flex;
          justify-content: space-between;
          width: 48%;
        }
        .calc-input {
          order: 1;
          max-width: 35%;
        }
        .calc-image {
          order: 0;
          max-width: 60%;
        }
        img {
          width: 100%;
        }
        input {
          max-width: 100%;
          text-align: center;
        }
      }
      @include bp(sm-md) {
        .calc-card {
          margin-top: 24px;
        }
        input {
          height: 111px;
          font-size: 50px;
        }
      }
      @include bp(xs-sm) {
        .calc-card {
          margin-top: 20px;
        }
        input {
          height: 80px;
          font-size: 42px;
        }
      }
      @media (max-width: 575px) {
        input {
          height: 14vw;
          font-size: 8vw;
        }
      }
    }
    &-bottom {
      input {
        width: 100%;
        background: transparent;
        border: 2px solid #8888C849;
        color: white;
        &:focus, &:active {
          outline: 1px solid #8888C899;
        }
      }
      .calc-result, .calc-community {
        position: relative;
        &::after {
          content: "◎";
          position: absolute;
        }
      }
      .calc-community {
        color: white;
        &::before {
          content: "(Change to what you expect)";
          position: absolute;
          background-color: #1e1e3c;
          color: #fff8;
        }
      }
      .calc-result {
        color: #27E790;
        input {
          border: 2px solid #27E790;
          color: #27E790;
          &:focus, &:active {
            outline: 1px solid #27E79055;
          }
        }
      }
      @include bp(md) {
        max-width: 770px;
        margin: 270px auto 0;
        .calc-desc {
          font-size: 30px;
          width: 360px;
          position: absolute;
          top: 50%;
          transform: translate(24px, -50%);
          line-height: 1.2;
        }
        input {
          height: 130px;
          border-radius: 8px;
          text-align: right;
          font-size: 50px;
          padding-right: 90px;
          padding-left: 450px;
        }
        .calc-result, .calc-community {
          position: relative;
          &::after {
            font-size: 50px;
            right: 45px;
            top: 50%;
            transform: translateY(-55%);
          }
        }
        .calc-community {
          &::before {
            top: -1.1em;
            left: 15px;
            padding: 5px 10px;
            font-size: 16px;
          }
        }
        .calc-result {
          margin-top: 40px;
        }
      }
      @include bp(xs-md) {
        
      }
      @include bp(sm-md) {
        margin: 50px auto 0;
        .calc-desc {
          font-size: 30px;
          width: 360px;
          position: absolute;
          top: 50%;
          transform: translate(24px, -50%);
          line-height: 1.2;
        }
        input {
          height: 130px;
          border-radius: 8px;
          text-align: right;
          font-size: 50px;
          padding-right: 90px;
          padding-left: 400px;
        }
        .calc-result, .calc-community {
          position: relative;
          &::after {
            font-size: 50px;
            right: 45px;
            top: 50%;
            transform: translateY(-55%);
          }
        }
        .calc-community {
          &::before {
            top: -1.1em;
            left: 15px;
            padding: 5px 10px;
            font-size: 16px;
          }
        }
        .calc-result {
          margin-top: 24px;
        }
      }
      @include bp(xs-sm) {
        margin: 40px auto 0;
        .calc-desc {
          font-size: 22px;
          width: 310px;
          position: absolute;
          top: 24px;
          transform: translate(24px, 0);
          line-height: 1.2;
        }
        input {
          border-radius: 8px;
          text-align: right;
          font-size: 40px;
          padding-right: 66px;
        }
        .calc-result, .calc-community {
          position: relative;
          &::after {
            font-size: 40px;
            right: 30px;
            bottom: 36px;
          }
        }
        .calc-community {
          &::before {
            top: -1.1em;
            left: 15px;
            padding: 5px 10px;
            font-size: 16px;
          }
          input {
            height: 160px;
            padding-top: 62px;
          }
        }
        .calc-result {
          margin-top: 24px;
          input {
            height: 134px;
            padding-top: 35px;
          }
        }        
      }
      @media (max-width: 575px) {
        .calc-desc {
          max-width: calc(100% - 46px);
        }
      }
    }
  }
  
  .nft-3d {
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 80px;
    box-sizing: border-box;
    background: url('/assets/img/site/card-3d.jpg') center center no-repeat;
    background-size: cover;
  }
}



.typeform-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


.faq {

	@include bp(md) {
		margin-bottom: 80px;
	}
}


.accordion-item {
	background: #fff;

}
  
  .accordion-button:focus {
	box-shadow: none;
}
  
  .accordion-button {
	background: #fff;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 16px;
	padding: 1.5rem 2rem;
  
	@include bp(md) {
	  font-size: 22px;
	}
  }
  
  .accordion-body {
	padding: 1.5rem 2rem;
  }
  
  .accordion-button:not(.collapsed) {
	background: #fff;
	color: #383B3F;
  }
  




.fees-discounts {

  .box-bordered {

    &.regular {
      padding: 25px 20px 15px;
    }

    @include bp(xs-sm) {
      h3 {
        font-size: 22px;
      }
    }

    img {
      width: 90px;

      @include bp(md) {
        width: 160px;
      }
    }
  }
}






