@font-face {
	font-family:'Gotham-Book';
	src:	url('../fonts/gotham/book/Gotham-Book_gdi.eot');
	src:	url('../fonts/gotham/book/Gotham-Book_gdi.eot?#iefix') format('embedded-opentype'),
			url('../fonts/gotham/book/Gotham-Book_gdi.woff') format('woff'),
			url('../fonts/gotham/book/Gotham-Book_gdi.ttf') format('truetype'),
			url('../fonts/gotham/book/Gotham-Book_gdi.otf') format('opentype'),
			url('../fonts/gotham/book/Gotham-Book_gdi.svg#Gotham-Book') format('svg');
	font-weight: 325;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
}

@font-face {
	font-family:'Gotham-Medium';
	src:	url('../fonts/gotham/medium/Gotham-Medium_gdi.eot');
	src:	url('../fonts/gotham/medium/Gotham-Medium_gdi.eot?#iefix') format('embedded-opentype'),
			url('../fonts/gotham/medium/Gotham-Medium_gdi.woff') format('woff'),
			url('../fonts/gotham/medium/Gotham-Medium_gdi.ttf') format('truetype'),
			url('../fonts/gotham/medium/Gotham-Medium_gdi.otf') format('opentype'),
			url('../fonts/gotham/medium/Gotham-Medium_gdi.svg#Gotham-Medium') format('svg');
	font-weight: 350;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
}

@font-face {
	font-family:'Gotham-Bold';
	src:	url('../fonts/gotham/bold/Gotham-Bold_gdi.eot');
	src:	url('../fonts/gotham/bold/Gotham-Bold_gdi.eot?#iefix') format('embedded-opentype'),
			url('../fonts/gotham/bold/Gotham-Bold_gdi.woff') format('woff'),
			url('../fonts/gotham/bold/Gotham-Bold_gdi.ttf') format('truetype'),
			url('../fonts/gotham/bold/Gotham-Bold_gdi.otf') format('opentype'),
			url('../fonts/gotham/bold/Gotham-Bold_gdi.svg#Gotham-Bold') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
}

