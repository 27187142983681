* {
    box-sizing: border-box;
}

html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    font-size: 16px;
    line-height: 26px;

    @include bp(xs-sm) {
        overflow-x: hidden;
    }
}

body {
    background: #030304;
    color: $text-color;
    font-family: $font-family-base;
    box-sizing: border-box;

    @include bp(sm) {
        &::after {
            display: none;
            content: '';
        }
    }
}

.subpage {
    padding-top: 140px;
}

#sk-holder {
    position: relative;
    z-index: 9999;
}