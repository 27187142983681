/***** MODAL PROPERTIES *****/

.ripple {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  -webkit-transform: translateZ(0); /* to contain zoomed ripple */
  transform: translateZ(0);
  border-radius: inherit; /* inherit from parent (rounded buttons etc) */
  pointer-events: none; /* allow user interaction */
  // animation: ripple-shadow 0.4s forwards;
  // -webkit-animation: ripple-shadow 0.4s forwards;
}
.rippleWave {
  backface-visibility: hidden;
  position: absolute;
  border-radius: 50%;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  background: rgba(190, 190, 190, 0.5);
  opacity: 0.45;
  animation: ripple 2s forwards;
  -webkit-animation: ripple 2s forwards;
}
@keyframes ripple-shadow {
  0% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  20% {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}
@-webkit-keyframes ripple-shadow {
  0% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  20% {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes ripple {
  to {
    transform: scale(24);
    opacity: 0;
  }
}
@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(24);
    opacity: 0;
  }
}

.testimonials {
  .swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
  }

  .swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background: #fff;
  }
}

.block .swiper .swiper-slide {
  transform: scale(0.9);
  transition: all .4s ease-in-out !important;

  &.swiper-slide-active {
    transform: scale(1);
  }
}

.section--featured {
  perspective: 1000;
  -webkit-perspective: 1000;
  width: 100%;
  overflow: hidden;
  background: #0f0e10;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  min-height: 100vh;
  margin: 0;
  box-sizing: border-box;
  padding-top: 50px;

  @include bp(xs-sm) {
    min-height: 100vh;
  }

  .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  @media (min-width: 576px) {
    .col-sm-2 {
      -webkit-box-flex: 0;
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
  }
  .col-2 {
    -webkit-box-flex: 0;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .row + .row {
    padding-left: 10px;
    margin-left: 10px;
  }

  .shadow,
  .shadow span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .shadow {
    background: linear-gradient(45deg, black, rgba(0, 0, 0, 0.3));
  }

  .shadow span {
    background: linear-gradient(to top, #000, transparent);

    @include bp(sm) {
      background: linear-gradient(to bottom, #0d0d0edb, transparent);
    }
  }

  img {
    margin: 0;
    width: 100%;
    display: block;
  }

  .line {
    width: 100%;
    position: absolute;
    animation: marquee 100s linear infinite;
    top: -150px;
    z-index: 0;
  }

  .line.second {
    top: 160px;
  }

  .line.third {
    top: 470px;
  }

  .line > div {
    width: 200%;
    height: 300px;
  }

  .row-container {
    width: 2300px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: -1600px;
    transform: rotateX(25deg) rotateY(-12deg) rotateZ(11deg) translateX(50%)
      translateY(-45px);
    z-index: 0;

    @include bp(sm-md) {
      transform: rotateX(25deg) rotateY(-12deg) rotateZ(15deg) translateX(50%)
        translateY(-50px);
    }

    @include bp(md) {
      transform: rotateX(20deg) rotateY(-13deg) rotateZ(14deg) translateX(50%)
        translateY(-50px);
    }
  }

  .line .row {
    width: 50%;
    float: left;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.stripes {
  position: absolute;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(auto-fill, 40px);
  transform: skewY(-8deg);
  width: 100%;
  height: 100%;
  display: grid;
  z-index: 0;

  @include bp(md) {
    grid-template-rows: repeat(auto-fill, 70px);
  }

  &.no-skew {
    transform: skewY(0deg);
  }
}

.intro .stripes {
  transform-origin: 0;
}

.page-launchpad .preview .stripes {
  // background: linear-gradient(45deg, #2cb1c4, #49c8ae);
  // background: linear-gradient(45deg, #161644, #282874);

  overflow: hidden;

  top: -70px;
  height: 70vh;

  @include bp(sm) {
    height: 85vh;
  }

  & + .text-center {
    margin-top: 12vh;

    @include bp(sm) {
      margin-top: 15vh;
    }
  }

  span {
    &:nth-child(1) {
      grid-column: 12;
      grid-row: 3;
      background: #2faba1;
    }
    &:nth-child(2) {
      background: linear-gradient(45deg, #90e1a7, #ddf8d3);
      grid-column: 12;
      grid-row: 4;
      margin-left: 50%;
    }

    &:nth-child(3) {
      grid-area: 1 / 4 / span 1 / span 3;
      background: #2d2d83;
    }

    &:nth-child(4) {
      grid-area: 5 / 1 / span 1 / span 2;
      background: linear-gradient(45deg, #1b1b50 0%, #24358f 100%);
    }

    &:nth-child(5) {
      background: #3adfe2;
      grid-area: 4 / 1 / span 1 / span 1;
    }
  }
}
