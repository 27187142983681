// rem fallback - credits: http://zerosixthree.se/

@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin line-height($size) {
    line-height: $size;
    line-height: calculateRem($size);
}

@mixin transition-delay($delay) {
    -webkit-transition-delay:   $delay;
    -moz-transition-delay:      $delay;
    -o-transition-delay:        $delay;
    transition-delay:           $delay;
}

@mixin transform($transform) {
    -webkit-transform:          $transform;
    -moz-transform:             $transform;
    -ms-transform:              $transform;
    -o-transform:               $transform;
    transform:                  $transform;
}

// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
    @if $xy == xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        @include transform(translateX(-50%) translateY(-50%));
    }
    @else if $xy == x {
        left: 50%;
        right: auto;
        @include transform(translateX(-50%));
    }
    @else if $xy == y {
        top: 50%;
        bottom: auto;
        @include transform(translateY(-50%));
    }
}

@mixin clearfix() {
    *{zoom: 1;}
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

