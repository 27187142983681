// @import "bourbon";
@import "framework/theme.scss";
@import "framework/bootstrap-scss-5/bootstrap.scss";

// Partials
@import 'partials/fonts.scss';
@import 'partials/layout.scss';
@import 'partials/mixins.scss';
@import 'partials/animations.scss';
@import 'partials/helpers.scss';

// Basics
@import 'base.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'plugins.scss';

// Theme
@import 'forms.scss';
@import 'typography.scss';
@import 'blocks.scss';
@import 'elements.scss';
@import 'tables.scss';
