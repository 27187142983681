$padding-horisontal:		16px;

select {
	-webkit-appearance:		none;
	 -moz-appearance:		none;
	 appearance:			none;
}

.form-control {
	padding-left: $padding-horisontal;
	padding-right: $padding-horisontal;
	height: 50px;
	border-radius: 25px;
	background-color: #fff;
	border: 1px solid #aaa;
	box-shadow: none;
	line-height: 32px;

	&:focus {
		box-shadow: none;
		border-color: $brand-primary;
	}
}

.input-group-addon {
	border: 2px solid $border-color;
	padding-left: $padding-horisontal;
	padding-right: $padding-horisontal;

	&:first-of-type {
		border-left: 2px solid $border-color;
	}
}

.input {

	
}