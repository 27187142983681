// Bootstrap overrides
$table-border-color:                #f0f2f3;
$font-family-base:                    'apparat', 'inter', 'Helvetica Neue', sans-serif;
$grid-columns:                        24 !default;


// Brand colors
$brand-primary: #2BEC96;
$brand-secondary: #7A3AC8; // Green

// Backgrounds
$light-gray-bg: #f0f2f3; // Alt bg color, light gray

// Grayscale
$white: #ffffff;
$black: #242425;

// Font colors
$headline-color: #33343c;
$text-color: #686c72; // Paragraph text color
$text-color-light: #a6acb3; // Used in tables mostly
$text-color-medium: #66776d;

$gray-5: #888a8d; // For paragraphs

// Basic elements
$border-color: $table-border-color;
$inactive-element: #e9e9e9; // Used for inactive elements such as button, radios, slider dots/navs, etc

// Buttons

// Sizes
$header-height: 76px;

// Flickity
$dot-dimension: 12px;

// Forms
$form-height: 46px;

$text-color-dark: #3b4149;

$purple-link: #b0b0ff;

$main-purple: #9A51FB;
$main-pink: #e9a509;
$main-green: #6dc57b;
$main-yellow: #eecf59;
$main-blue: #5085e5;

$light-teal: #CAF9FE;
$light-purple: #EEE9FF;
$light-green: #DEFFE9;
$light-yellow: #FEF6CF;
$light-blue: #DFF0FF;
$light-pink: #f5eefd;
