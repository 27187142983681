


.footer-cta {
	padding: 5% 0;
	background: linear-gradient(225deg, #14141900 0%, #060607 100%);
}

.cta-box {

$speed: .2s;
$pad_space: 10px;

.link {
  position: relative;
  width: 100%;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 40px;

  h1 {
    margin-bottom: 0;
  }
}

.link, a h1, a h4 {
  transition: all .4s ease-out;
  color: #3a3a3d;
}

@include bp(xs-sm) {
	h4 {
		font-size: 14px;
	}

	.link {
		padding: 22px 24px;
	}
}

div.arrow {
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	position: absolute;
  transition: $speed ease-in-out;
  margin-top: -20px;
	
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		border-width: 2px 2px 0 0;
		border-style: solid;
		border-color: #fafafa;
		transition: $speed ease;
		display: block;
		transform-origin: 100% 0;
    opacity: 0.3;
	}
	
	&:after {
		content: '';
		float: left;
		position: relative;
		top: -100%;
		width: 100%;
		height: 0%;
		transform: rotate(45deg);
		border-width: 0 2px 0 0;
		border-style: solid;
		border-color: #fafafa;
		transform-origin: 100% 0;
		transition:$speed ease-out;
    margin-top: 1px;
    opacity: 0.3;
	}
	

	&:hover::before {
		border-color: orange;
		transform: scale(.9);
	}
}
.link:hover {
  border-color: #fff;

  h4, h1 {
    color: #fff;
  }

  div.arrow{
    margin-left: 20px;
    margin-top: -40px;
  }

  .arrow:before {
    opacity: 1;
  }

  .arrow:after {
		transform: rotate(45deg);
		border-color: #fff;
		height: 180%;
    opacity: 1;
	}
}
div.left{
	margin-top : 32px;
	margin-left : $pad_space*3;
	transform: rotate(-135deg);
	float:left;
}
div.right{
	margin-right : $pad_space*3;
	transform: rotate(45deg);
	position: absolute;
  top: 50%;
  right: 70px;

  @include bp(xs-sm) {
	transform: rotate(45deg) scale(0.6);
    right: 20px;
  }
}
}


.footer {
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	position: relative;
	padding-bottom: 50px;
	background-image: linear-gradient(225deg, #141419 0%, #060607 100%);

	p {
		color: #3f4144;
	}

	ul {
		padding: 0;
	}

	@include bp(xs-sm) {
		padding: 20px;
		
		h4 {
			margin-top: 30px;
			font-size: 12px;
		}
	}
  
	.col {
	  min-width: 150px;
  
	  @include bp(sm) {
		padding: 0 50px;
		margin: 0 0 30px;
	  }
	}
  
	.footer-logo {
	  width: 150px;
	  margin-top: -7px;
	  margin-bottom: 30px;
	}
  
	#footer-end {
	  position: absolute;
	  top: 70%;
	  left: 0;
	  background: linear-gradient(90deg, #3e4ece, #6772e5);
	  width: 100%;
	  height: 65%;
	  z-index: -1;
	  overflow: hidden;
	  margin-top: 80px;
	}
  
	.container {
	  padding-top: 50px;
	  
	  @include bp(sm) {
		padding-top: 100px;
	  }
	}
  
	@include bp(xs-sm) {
	  .col-sm-6 {
		padding: 0 50px;
		margin: 0 0 30px;
	  }
	}
  
	@include bp(sm) {
	  max-height: 700px;
	}
  
	.cta-section {
	  margin-bottom: 40px;
	  padding-bottom: 60px;
  
	  @include bp(xs-sm) {
		padding: 30px 30px 50px;
	  }
  
	  h2 {
		font-size: 30px;
		color: #fff;
		font-weight: 600;
	  }
  
	  h4 {
		text-transform: none;
		color: #68a9ed;
		font-size: 16px;
		font-weight: 300;
		display: inline-block;
	  }
  
	  @include bp(xs-sm) {
		fieldset {
		  width: 100%;
		}
	  }
  
	  @include bp(sm) {
		.form-inline {
		  justify-content: center;
  
		  button {
			right: 0;
			top: 0;
			height: 50px;
			border-radius: 0 30px 30px;
		  }
		}
	  }
	}
  
	h4 {
		color: #3a3a3d;
	  font-size: 16px;
	  font-weight: 600;
	  text-transform: none;
	  letter-spacing: 0;
  
	  @include bp(xs-sm) {
	  }
  
	  @include bp(sm) {
		margin-bottom: 20px;
	  }
	}
  
	ul {
	  li {
		display: block;
		color: #747578;
  
		&.active a {
			color: #fff;
		}

		@include bp(sm) {
		  margin: 0 0 4px;
		}
  
		a {
		  color: rgba(#63696b, 0.8);
		  transition: 0.3s ease-out;
		  font-size: 18px;
		  line-height: 34px;
		  
  
		  &:hover {
			color: #fff;
		  }
  
		  &.soon {
			text-decoration: line-through;
			opacity: 0.5;
			pointer-events: none;
		  }
		}
	  }
	}
  
	.social {
	  background: rgba(255, 255, 255, 0.03);
	  padding: 30px 0;
  
	  @include bp(sm) {
		margin-top: 60px;
	  }
  
	  h4 {
		color: #fff;
	  }
  
	  ul {
		li {
		  display: inline-block;
		  margin: 0 20px;
  
		  a {
			font-size: 30px;
		  }
		}
	  }
	}
  }
  