h1,
.h1 {
	font-size: 50px;
    line-height: 65px;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: $font-family-base;
}

h2 {
    font-size: 46px;
    color: #fff;
}

h3 {
    font-family: Apparat;
    font-weight: 500;
    font-size: 30px;
    color: #FFFFFF;
    letter-spacing: -0.75px;
    line-height: 36px;

    span {
        font-weight: 500;
    }
}

h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;

    &.tag {
        font-size: 20px;
        color: #FFFFFF;
        letter-spacing: 2.67px;
        font-weight: 300;
    }
}

p {
    

    @include bp(sm) {
        font-size: 17px;
        line-height: 28px;
    }

    &.lead {


        @include bp(xs-sm) {
            line-height: 26px !important;
            font-size: 1rem;
        }
    }

    & + .small {
        font-size: 0.875em;
        max-width: 440px;
        display: block;
        line-height: 20px;
    }
}

.headline {
    max-width: 640px;
    margin: 0 auto;
}

@include bp(xs-sm) {

    h1,
    .headline h1 {
        font-size: 30px;
        line-height: 40px;
    }

    h2,
    .headline h2 {
        font-size: 26px;
    }

    h3,
    .headline h3 {
        font-size: 30px;
    }

    .headline {

        p {
            text-align: center;
            margin: 0 auto;
        }
    }
}
h1, h2, h3, h4, h5, h6 {
    font-family: apparat, cursive;
}