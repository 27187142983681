.navbar {
	transition: all .2s ease-out;

	.navbar-brand {
		background: url('../img/site/logo-color.svg') top left no-repeat;
		background-size: contain;
		width: 230px;
		height: 36px;
		will-change: transition;
	}

	.logo {
		transition: all .3s ease-out;
		transform: scale(1);
	}

	// .navbar-collapse.collapse.show {
	// 	display: block !important;
	// }

	.navbar-nav {
		padding: 0;
		flex: 10 !important;
		justify-content: center;

		.nav-item {

			&.active a {
				opacity: 1;
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				padding: 10px 0 7px;
				text-shadow: 0 0 30px rgba(255, 255, 255, 0.4);
			}

			a {
				font-size: 18px;
				padding: 10px 0;
				margin: 0 15px;
				font-weight: 500;
				text-align: center;
				color: #fff;
				transition: .2s ease-out;
				opacity: 0.6;

				&:hover {
					opacity: 1;
					text-shadow: 0 0 30px rgba(255, 255, 255, 0.4);
				}
			}
		}

		& + .form-inline {
			width: 230px;
			justify-content: flex-end;
		}
	}


	.cta {
		margin-bottom: 0;

		@include bp(md) {
			width: 190px;
			display: flex;
			justify-content: end;
		}

		@include bp(xs-md) {
			display: none;
		}
		
		li {
			display: flex;
			margin-left: 10px;

			.btn {
				min-width: 100px;
				justify-content: center;
			}
		}
	}

}

.main-header {
	position: fixed;
	width: 100%;
	z-index: 10;
	padding:  0;
	top: 0;
	padding: 15px 0;
	transition: all .4s cubic-bezier(0.96, -0.01, 0, 1.04);
	box-sizing: border-box;
	background: transparent;

	.collapse.navbar-collapse.active {
		display: block !important;
	}

	// @include bp(xs-md) {
	// 	.navbar-nav .nav-item {
	// 		padding: 7px 0;
	// 	}

	// 	.navbar-toggler {
	// 		transform: scale(0.8);
	// 	}

	// 	.navbar .navbar-collapse.collapse.show {
	// 		display: block !important;
	// 		background: rgba(15, 17, 24, 0.8);
	// 		border-radius: 12px;
	// 		backdrop-filter: blur(6px);
	// 		padding: 15px;
	// 		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 20px 40px rgba(0, 0, 0, 0.14), 0 10px 14px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.1);
	// 	}

	// 	.navbar .navbar-collapse.collapse {
	// 		margin-top: 10px;
	// 	}

	// 	.apply-btn a {
	// 		width: calc(100% - 32px);
	// 		font-size: 16px !important;
	// 		box-sizing: border-box;
	// 		opacity: 1 !important;
	// 		font-weight: 500;
	// 		color: #1C1C2C !important;
	// 	}

	// 	.apply-btn.d-md-none {
	// 		display: block !important;
	// 	}
	// }

	.container {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.logo {
		background: url('../img/logo/logo-white.svg') top left no-repeat;
		background-size: contain;
		float: left;
		position: relative;
		z-index: 1;
		width: 190px;
		height: 30px;
	}
}


@include bp(sm) {

	.is-scrolled {

		.main-header {
			position: fixed;
			margin: 0;
			background: rgba(29,29,31,0.72);
			backdrop-filter: saturate(180%) blur(20px);
			-webkit-backdrop-filter: saturate(180%) blur(20px);
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
			padding: 8px 0;

			// @include bp(xs-sm) {
			// 	background: rgba(255, 255, 255, 0.95);
			// }

			.nav-item.active a {
				opacity: 1;
			}

			.navbar {
				padding: 0 15px 5px;
			}

			/*background: rgba(63, 65, 68, 0.94);
			transform: translate3d(0px,0px,0px);
			transition: all .3s ease-out;

			.logo {
				background-image: url('../img/logo/grundtema-white.svg') !important;
				background-size: contain;
				margin-top: 14px;
			} */
		}

		#tabs-container {
			transform: translate3d(0px,126px,0px);
			transition: all .3s ease-out;
			top: 0;
		}

	}

	.menu-hidden {

		.main-header {
			transform: translate3d(0px,-100px,0px);
			transition: all .3s ease-out;
		}

		#tabs-container {
			transform: translate3d(0px,50px,0px);
			transition: all .3s ease-out;
		}
	}
}

// Custom mobile navigation
header {
	.nav-item.nav-item-logo {
		display: none;
	}
}
@include bp(xs-md) {

	.is-scrolled .main-header {

	}

	.is-scrolled .main-header:not(.active) {
		background: rgba(20, 26, 35, 0.9);
		padding: 3px 0;
	}

	.navbar-toggler {
		transform: scale(0.8);
	}

	header {

		#overlay {
			animation-duration: .4s;
			opacity: 0;
		}

		&.active {
			#overlay {
				z-index: 50;
				position: fixed;
				top: 0;
				right: 0;
				background: rgba(0, 0, 0, 0.7);
				width: 100%;
				height: 100%;
				backdrop-filter: blur(20px);
				-webkit-backdrop-filter: blur(20px);
				opacity: 1;
			}
			.navbar-toggler {
				visibility: hidden;
				z-index: -1;
			}
		}
		&:not(.active) {
			#overlay {
				display: none;
			}
			.navbar-toggler {
				visibility: visible;
				z-index: 999;
			}
		}
		.collapse.navbar-collapse {
			background-color: #141821;
			display: block;
			width: 320px;
			position: fixed;
			top: 0;
			bottom: 0;
			padding: 8px 0;
			z-index: 99;
			transition: 300ms;
			&:not(.show) {
				right: -320px;
			}
			&.show {
				right: 0px;
			}
		}
	
		.nav-item {
			padding: 4px 0;
			.navbar-link {
				display: block;
				text-align: left !important;
				padding: 12px 20px !important;
				border-radius: 10px;
			}

			a {
				font-size: 16px !important;
				font-weight: normal !important;
				background: rgba(255, 255, 255, 0.03);
				border-radius: 6px !important;
			}

			&.active {
				.navbar-link {
					border-bottom: none !important;
					background-color: #41454d;
				}
			}
			&.nav-item-logo {
				display: block;
				a {
					opacity: 0.8 !important;
					background: none !important;

					img {
						width: 160px;
					}
				}
				a:hover, a:active {
					opacity: 1 !important;
				}
			}
			&:not(.nav-item-logo), &:not(.nav-item-logo){
				.navbar-link:hover, .navbar-link:active {
					background-color: #5b5e63;
				}
			}
			&.apply-btn {
				a {
					margin: 0 15px !important;
					display: block;
					padding: 13px 0 !important;
					opacity: 1 !important;
					color: #1C1C2C !important;
					background: #2BEC96;
					font-weight: normal;
				}
			}
		}
	}
}
